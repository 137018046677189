<template>
  <div>
    <v-card>
      <v-data-table :headers="headers" :options.sync="options" :items="lista" :items-per-page="itemsPerPage"
        :loading="loadingTable" hide-default-footer class="elevation-1">
        <template v-slot:top>
          <ExpandableFilters classButton="py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0" iconButtom="mdi-upload"
            classTitle="col-0" :filters="filters" @datafilters="cargarLista(true)" />
          <v-col>
            <v-btn color="primary" small class="ma-2 white--text" @click="abrirDialogOrganizacion(null)">
              <v-icon left>mdi-plus</v-icon>
              Nueva
            </v-btn>
          </v-col>
        </template>
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="red" target="_blank" @click="abrirDialogOrganizacion(item)">
            <v-icon color="#ff9800">mdi-table-edit</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
            total-visible="10"></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOGO ORGANIZACION -->
    <v-dialog v-model="dialogOrg" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Datos de la Organizacion</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formDatosOrg">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <!-- <v-select
                      v-model="datosDevCheque.fin_account_id"
                      small
                      item-text="fin_account_name"
                      item-value="fin_account_id"
                      :items="cuentasBancarias"
                      label="Cuenta bancaria"
                      :rules="finAccountRule"
                    ></v-select> -->
                </v-col>
                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                  <v-text-field v-model="datosOrg.group_name" label="Razón social" type="text" small></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                  <v-text-field v-model="datosOrg.group_name_local" label="Nombre comercial" type="text"
                    small></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                  <v-checkbox v-model="datosOrg.agente_retencion" label="Es Agente de retención" small></v-checkbox>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">

                  <v-select v-model="datosOrg.regimen_rimpe" small item-text="description" item-value="enum_id"
                    :items="regs" label="Régimen tributario"></v-select>
                </v-col>

                <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">
                  <v-checkbox v-model="datosOrg.contribuyente_especial" label="Es contribuyente especial"
                    small></v-checkbox>
                </v-col>
                <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">

                  <v-text-field v-model="datosOrg.codigo_contribuyente" label="Código del contribuyente" type="text" small
                    :disabled="datosOrg.contribuyente_especial == false"></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">

                  <v-text-field v-model="datosOrg.dia_declaracion" label="Día de declaración" type="text"
                    small></v-text-field>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
          <!-- <small>*campos obligatorios</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogOrg = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid class="white--text" @click="guardarOrg()">
            <v-icon>mdi-content-save</v-icon>
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from "../general/ExpandableFilters";

export default {
  name: "OrganizacionComponent",
  components: {
    ExpandableFilters,
  },
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "party_id" },
      { text: "Nombre", value: "group_name" },
      { text: "Nombre Comercial", value: "group_name_local" },

    ],
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    overlay: false,

    dialogOrg: false,
    filters: [
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-1 pl-0 pl-sm-2",
        v_model: "",
        label: "Código",
        type: "input_text",
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2 mb-1",
        v_model: "",
        label: "Organizacion",
        type: "input_text",
      },

    ],

    total_pagado: "",

    datosOrg: {
      "party_id": "",
      "group_name": "",
      "group_name_local": "",
      "agente_retencion": "",
      "regimen_rimpe": "",
      "contribuyente_especial": "",
      "codigo_contribuyente": "",
      "dia_declaracion": ""
    },
    currentPage: 0,
    pageCount: 1,
    itemsPerPage: 20,
    searchNumber: "",
    searchOrganizacion: "",
    regs: [],

    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",

  }),
  watch: {
    options: {
      handler() {
        this.cargarLista(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    /* refExiste() {
       if (this.valorChequeEc == 0 || this.valorChequeEc == "")
         return "No se encontró la referencia";
       else return true;
     },
 
     validarRefCosto() {
       if (
         this.datosDevCheque.refCosto != "" &&
         (this.costoChequeEc == 0 || this.costoChequeEc == "")
       ) {
         return "No se encontró una transacción";
       } else {
         return true;
       }
     },*/
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista(filtrar) {

      if (filtrar == true) {
        this.currentPage = 0;
      }

      this.setLoadingTable(true);
      this.setUrl("organizacion");
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,

        },
      }).then((res) => {
        this.lista = res.data.detail.lista;
        this.regs = res.data.detail.regimenes;
        this.pageCount = res.data.detail.page_count;
        this.setLoadingTable(false);
      }).then(() => { });
    },



    abrirDialogOrganizacion(item) {

      if (item != null) {
        this.datosOrg.party_id = item.party_id;
        this.datosOrg.group_name = item.group_name;
        this.datosOrg.group_name_local = item.group_name_local;
        this.datosOrg.agente_retencion = false;
        this.datosOrg.dia_declaracion = item.dia_declaracion
        if (item.agente_retencion == "t") {
          this.datosOrg.agente_retencion = true;
        }
        this.datosOrg.regimen_rimpe = item.regimen_rimpe;
      } else {
        this.datosOrg.party_id = "";
        this.datosOrg.group_name = "";
        this.datosOrg.group_name_local = "";
        this.datosOrg.agente_retencion = "";
        this.datosOrg.regimen_rimpe = "";
        this.datosOrg.dia_declaracion = "";
      }

      this.dialogOrg = true;
    },

    guardarOrg() {
      this.setLoadingTable(true);
      this.setUrl("organizacion");
      this.lista = [];
      this.requestApi({
        method: "POST",
        data: {
          party_id: this.datosOrg.party_id,
          group_name: this.datosOrg.group_name,
          group_name_local: this.datosOrg.group_name_local,
          agente_retencion: this.datosOrg.agente_retencion,
          contribuyente_especial: this.datosOrg.contribuyente_especial,
          codigo_contribuyente: this.datosOrg.codigo_contribuyente,
          regimen_rimpe: this.datosOrg.regimen_rimpe,
          dia_declaracion: this.datosOrg.dia_declaracion

        },
      }).then((res) => {
        //this.lista = res.data.detail;      
        this.cargarLista();
        this.dialogOrg = false;
        this.setLoadingTable(false);
      }).then(() => { });

    },

    handlePageChange(value) {
      //this.currentPage = value
      this.cargarLista(false);
    },


    mounted() {
      this.setTitleToolbar("ORGANIZACIONES");
      //this.geEstablishment()
      //this.getFormasPago();
      //this.cargarCuentasBancarias();
    },
  }

};

</script>
  